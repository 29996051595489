import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="modal"
export default class extends Controller {
  connect() {
    this.modal = new bootstrap.Modal(this.element)
  }

  close(e) {
    if (this.isOpen()) {
      this.modal.hide()
    }
  }

  isOpen() {
    return this.element.classList.contains("show")
  }

  closeAfterSubmit(e) {
    if (e.detail.success) {
      this.close(e)
    }
  }
}
