import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["selectedCandidateJobs"]

  updateSelectedCandidateJobs() {
    const selectedCandidateJobs = Array.from(document.querySelectorAll('.candidate-tag'))
      .map(tag => tag.dataset.candidateJobId)
    this.selectedCandidateJobsTarget.value = JSON.stringify(selectedCandidateJobs)
  }

  remove(event) {
    event.preventDefault()
    event.target.closest('.candidate-tag').remove()
    const candidate_job_id = event.target.dataset.candidateJobId

    document.querySelectorAll('.selected-candidate-jobs-ids').forEach(input => {
      if (input.value.includes(candidate_job_id)) {
        input.remove()
      }
    })

    this.clearCandidatesWithoutEmailList()
    this.checkIfNeedToCloseModal()
  }

  checkIfNeedToCloseModal() {
    const candidates_with_email = document.querySelector(`.candidates-with-email`)

    if (candidates_with_email && candidates_with_email.children.length === 0) {
      const modal = this.element.closest('.modal')
      const modalInstance = bootstrap.Modal.getInstance(modal)
      modalInstance.hide()
    }
  }

  clearCandidatesWithoutEmailList() {
    const candidates_without_email = document.querySelector('.candidates-without-email')

    if (candidates_without_email && candidates_without_email.children.length === 0) {
      document.querySelector('.candidates-without-email-title').classList.add('d-none')
      document.querySelector('.candidates-without-email').classList.add('d-none')
    }
  }

}
