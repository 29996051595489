import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["button"]

  connect() {
    this.updateActiveButton()
  }

  toggle(event) {
    this.updateActiveButton()
  }

  updateActiveButton() {
    this.buttonTargets.forEach(button => {
      button.classList.toggle('active', button.href === window.location.href)
    })
  }
}
