import { Controller } from '@hotwired/stimulus';
import { Loader } from "@googlemaps/js-api-loader";  // Importe Loader ao invés de Autocomplete

// Connects to data-controller="address"
export default class extends Controller {
  static targets = ["input"];

  connect() {
    this.initAutocomplete();
  }

  initAutocomplete() {
    const loader = new Loader({
      apiKey: this.element.dataset.mapsApiKey,
      libraries: ["places"]
    });

    loader.load().then((google) => {
      const autocomplete = new google.maps.places.Autocomplete(this.inputTarget, {
        types: ['(cities)']
      });

      autocomplete.addListener('place_changed', () => this.placeChanged(autocomplete));
      this.inputTarget.addEventListener('blur', () => this.clearIfInvalidPlace(autocomplete));
    }).catch(e => {
      console.error("Erro ao carregar a API do Google Maps", e);
    });
  }

  placeChanged(autocomplete) {
    const place = autocomplete.getPlace();
    const addressComponents = place.address_components;
    let city = '';
    let state = '';
    let country = '';

    for (let component of addressComponents) {
      const types = component.types;

      if (types.includes('locality')) {
        city = component.long_name;
      } else if (types.includes('administrative_area_level_1')) {
        state = component.long_name;
      } else if (types.includes('country')) {
        country = component.long_name;
      }
    }

    let formattedLocation = this.formatLocation(city, state, country);
    this.inputTarget.value = formattedLocation;
  }

  clearIfInvalidPlace(autocomplete) {
    const place = autocomplete.getPlace();
    if (!place || !place.address_components) {
      this.inputTarget.value = '';
    }
  }

  formatLocation(city, state, country) {
    return country === 'Estados Unidos' ? `${city}, ${state}` : `${city}, ${state}, ${country}`;
  }
}
