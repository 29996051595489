import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="candidate-job-bulk-selector"
export default class extends Controller {
  static targets = ["checkbox", "selectAllCheckbox", "actionButtons"];

  connect() {
    this.updateBulkActionsVisibility();
    this.updateSelectAllCheckbox();
  }

  updateBulkActionsVisibility() {
    const checkboxes = this.checkboxTargets;
    const bulkActions = this.actionButtonsTarget;
    const listPipelineToggle = document.getElementById('list-pipeline-view-toggle');

    if (checkboxes.some(checkbox => checkbox.checked)) {
      bulkActions.classList.remove('d-none');
      listPipelineToggle.classList.add('d-none');
    }
    else {
      bulkActions.classList.add('d-none');
      listPipelineToggle.classList.remove('d-none');
    }
  }

  toggleCheckbox(event) {
    this.updateSelectAllCheckbox();
    this.updateBulkActionsVisibility();
  }

  toggleSelectAll(event) {
    const isChecked = event.target.checked;

    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = isChecked;
    });

    this.selectAllCheckboxTarget.indeterminate = false;
    this.updateBulkActionsVisibility();
  }

  updateSelectAllCheckbox() {
    const totalCheckboxes = this.checkboxTargets.length;
    const checkedCheckboxes = this.checkboxTargets.filter(checkbox => checkbox.checked).length;

    if (checkedCheckboxes === 0) {
      this.selectAllCheckboxTarget.checked = false;
      this.selectAllCheckboxTarget.indeterminate = false;
    } else if (checkedCheckboxes === totalCheckboxes) {
      this.selectAllCheckboxTarget.checked = true;
      this.selectAllCheckboxTarget.indeterminate = false;
    } else {
      this.selectAllCheckboxTarget.checked = false;
      this.selectAllCheckboxTarget.indeterminate = true;
    }
  }
}
