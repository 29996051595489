import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="candidate-job-spinner"
export default class extends Controller {
  static targets = ["table", "spinner"];

  showSpinner() {
    this.spinnerTarget.classList.remove("d-none");
    this.tableTarget.classList.add("table-loading");
  }

  hideSpinner() {
    this.spinnerTarget.classList.add("d-none");
    this.tableTarget.classList.remove("table-loading");
  }
}
