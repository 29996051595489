import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  connect() {
    try {
      Sortable.create(this.element, {
        animation: 150,
        onStart: this.dragStart.bind(this),
        onEnd: this.dragEnd.bind(this),
        filter: '.profile-column', // Prevent dragging of profile column
        onMove: (evt) => {
          return evt.related.className.indexOf('profile-column') === -1;
        }
      })
    } catch (error) {
      console.error("Error initializing Sortable:", error)
    }
  }

  dragStart(event) {
    event.item.classList.add('dragging')
  }

  dragEnd(event) {
    event.item.classList.remove('dragging')
    this.updatePositions()
  }

  updatePositions() {
    const items = this.element.querySelectorAll('.job-column-settings-fields')
    if (items.length === 0) {
      console.warn("No job column settings fields found")
      return
    }

    items.forEach((item, index) => {
      const positionInput = item.querySelector(".position")
      const positionDisplay = item.querySelector(".position-display")
      
      if (positionInput) {
        positionInput.value = index + 1
      } else {
        console.warn(`Position input not found for item ${index + 1}`)
      }

      if (positionDisplay) {
        positionDisplay.textContent = index + 1
      } else {
        console.warn(`Position display not found for item ${index + 1}`)
      }
    })
  }
}